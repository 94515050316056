import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// MD React components
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";

// MD React examples
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import Footer from "components/MDComponents/Footer";
import ComplexStatisticsCard from "components/MDComponents/Cards/StatisticsCards/ComplexStatisticsCard";

// API
import { getTools } from "util/APIHelper";

// Main components
import RolodexCard from "components/Components/Cards/RolodexCard";

// KEY
import { v4 as uuidv4 } from "uuid";

function ToolAll() {
  const [rolodexData, setRolodexData] = useState([]);

  useEffect(() => {
    const runAsync = async () => {
      const rolodexResponse = await getTools();
      setRolodexData(rolodexResponse.data.message);
    };
    runAsync();
  }, []);

  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Bookings"
                  count={281}
                  percentage={{
                    color: "success",
                    amount: "+55%",
                    label: "than last week",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Today's Users"
                  count="2,300"
                  percentage={{
                    color: "success",
                    amount: "+3%",
                    label: "than last month",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="store"
                  title="Revenue"
                  count="34k"
                  percentage={{
                    color: "success",
                    amount: "+1%",
                    label: "than yesterday",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="Followers"
                  count="+91"
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Just updated",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        {/* Start Cards Container */}
        <MDBox mt={2}>
          <Grid container spacing={3}>
            {rolodexData.map((data) => (
              <Grid key={uuidv4()} item xs={12} md={6} lg={4}>
                <MDBox mt={3}>
                  <RolodexCard
                    key={uuidv4()}
                    image={data.image}
                    title={data.title}
                    description={data.description}
                    tags={data.tags}
                    action={actionButtons}
                  />
                </MDBox>
              </Grid>
            ))}
          </Grid>
        </MDBox>
        {/* End Cards Container */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ToolAll;
