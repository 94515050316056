// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// MD React components
import MDBox from "../../../components/MDBase/MDBox";
import MDTypography from "../../../components/MDBase/MDTypography";
import MDButton from "../../../components/MDBase/MDButton";

// Authentication layout components
import BasicLayout from "layouts/auth/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useMaterialUIController } from "context/md";
import { auth } from "fb/firebase.utils";

function Logout() {
  const [controller] = useMaterialUIController();
  const { loggedIn } = controller;
  const navigate = useNavigate();

  if (loggedIn && auth.currentUser) {
    auth.signOut();
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1} mb={1}>
            You are now logged out
          </MDTypography>
        </MDBox>
        <MDBox pt={0} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => {
                  navigate("/login");
                }}
              >
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={0} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                or back{" "}
                <MDTypography
                  component={Link}
                  to="/"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Home
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Logout;
