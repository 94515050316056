// Image import
import logoReact from "assets/images/small-logos/logo-react.svg";

import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// MD Base components
import MDTypography from "components/MDBase/MDTypography";

// Base components
import Banner from "components/Base/Banner";

function LinkBanner({ link, bgImage, title }) {
  return (
    <Link to={link}>
      <Banner image={bgImage}>
        <MDTypography
          variant="h3"
          color="white"
          p={2}
          sx={{ textShadow: "2px 3px 5px rgba(0,0,0,0.5)" }}
        >
          {title}
        </MDTypography>
      </Banner>
    </Link>
  );
}

// Setting default values for the props of DefaultItem
LinkBanner.defaultProps = {
  title: "",
  bgImage: logoReact,
  link: "/",
};

// Typechecking props for the DefaultItem
LinkBanner.propTypes = {
  title: PropTypes.string,
  bgImage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  link: PropTypes.string,
};

export default LinkBanner;
