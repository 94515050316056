// MD React components
import MDBox from "components/MDBase/MDBox";

// MD React examples
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import Footer from "components/MDComponents/Footer";

import Dropzone from "../../../components/Components/Dropzone";

function TestUpload() {

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Dropzone className='dropzone'/>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TestUpload;
