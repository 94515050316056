// Image import
import logoReact from "assets/images/small-logos/logo-react.svg";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";

// MD Base components
import MDAvatar from "components/MDBase/MDAvatar";

function Icons({ title, size, variant, background, image, grid }) {
  if (grid) {
    return (
      <Grid item>
        <Tooltip title={title} placement="bottom">
          <MDAvatar
            src={image}
            alt={title}
            size={size}
            variant={variant}
            shadow="xl"
            sx={{
              background,
              p: 1,
              m: 1,
              borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl,
            }}
          />
        </Tooltip>
      </Grid>
    );
  }
  return (
    <Tooltip title={title} placement="bottom">
      <MDAvatar
        src={image}
        alt={title}
        size={size}
        variant={variant}
        shadow="xl"
        sx={{
          background: { background },
          p: 1,
          m: 1,
          borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl,
        }}
      />
    </Tooltip>
  );
}

// Setting default values for the props of DefaultItem
Icons.defaultProps = {
  title: "",
  size: "lg",
  variant: "rounded",
  background: "#222222",
  image: logoReact,
  grid: false,
};

// Typechecking props for the DefaultItem
Icons.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  background: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  grid: PropTypes.bool,
};

export default Icons;
