import { useCallback, useState } from "react";
import {useDropzone} from "react-dropzone";
import PropTypes from "prop-types";
import DropzoneRoot from "./DropzoneRoot";
import MDTypography from "../../MDBase/MDTypography";
import MDInput from "../../MDBase/MDInput";
import MDBox from "../../MDBase/MDBox";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDButton from "../../MDBase/MDButton";

function Dropzone ({className}) {
  const [files, setFiles] = useState([])
  const [rejected, setRejected] = useState([])

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {

    // Handle accepted files
    if (acceptedFiles?.length) {
      setFiles(previousFiles => [
        ...previousFiles,
        ...acceptedFiles.map(file =>
          Object.assign(file, { preview: URL.createObjectURL(file)})
        )
      ])
    }

    // Handle rejected files
    if (rejectedFiles?.length) {
      setRejected(previousFiles => [...previousFiles, ...rejectedFiles])
    }
  }, [])

  // Handle file restriction
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept:{
    'image/*':[]
    }})

  // Remove file function for accepted files
  const removeFile = name => {
    setFiles(files => files.filter(file => file.name !== name))
  }

  // Remove file function for rejected files
  const removeRejected = name => {
    setRejected(files => files.filter(({file}) => file.name !== name))
  }

  // Handle upload file to cloudinary
  const handleSubmit = async e => {
    e.preventDefault()

    if (!files?.length) return

    const formData = new FormData()
    files.forEach(file => formData.append('file', file))
    formData.append('upload_preset', 'test_upload')

    const URL = `${process.env.REACT_APP_API_URL}/upload`
    const data = await fetch(URL, {
      method: 'POST',
      body: formData
    }).then(res => res.json())
  }

  return (
    <form onSubmit={handleSubmit}>
      <MDBox>
        <DropzoneRoot sx={{ cursor: "pointer" }} {...getRootProps({
          className: className
        })}>
          <MDInput {...getInputProps()} />
          <Grid container justifyContent="center">
            <Grid item>
              <MDBox display="flex" textAlign="center" p={2}>
                <MDTypography variant="body2" fontWeight="light" color="text" opacity={isDragActive?0.6:1}>Drop files here to upload</MDTypography>
              </MDBox>
            </Grid>

          </Grid>
        </DropzoneRoot>

        <MDButton type="submit">
          Upload to S3
        </MDButton>

        {/* Accepted file preview */}
        <ul>
          {files.map(file => (
            <li key={file.name}>
              <img
                src={file.preview}
                alt={file.name}
                width={100}
                height={100}
                onLoad={() => {
                  URL.revokeObjectURL(file.preview)
                }}
              />
              <MDButton
                onClick={() => removeFile(file.name)}
              >
                <Icon>delete</Icon>
              </MDButton>
              <MDTypography>{file.name}</MDTypography>
            </li>
          ))}
        </ul>

        {/* Rejected file preview */}
        <ul>
          {rejected.map(({file, errors}) =>(
            <li key={file.name}>
              <MDBox>
                <MDTypography variant="body1">
                  {file.name}
                </MDTypography>
                <ul>
                  {errors.map(error => (
                    <li key={error.code}>{error.message}</li>
                  ))}
                </ul>
              </MDBox>
              <MDButton onClick={() => removeRejected(file.name)}>
                <Icon>delete</Icon>
              </MDButton>
            </li>
          ))}
        </ul>
      </MDBox>
    </form>
  );

}

Dropzone.propTypes = {
  className: PropTypes.string.isRequired,
}

export default Dropzone;