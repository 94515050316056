import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// MD React components
import MDBox from "components/MDBase/MDBox";

// MD React examples
import Sidenav from "components/MDComponents/Sidenav";
import Configurator from "components/MDComponents/Configurator";

// MD React themes
import theme from "assets/theme";

// MD React Dark Mode themes
import themeDark from "assets/theme-dark";

// MD React routes
import routes from "routes";

// MD React contexts
import { useMaterialUIController, setMiniSidenav, setLogin, setUser, setUserInfo } from "context/md";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

// Auth
import { auth } from "fb/firebase.utils";
import { onAuthStateChanged } from "firebase/auth";
import ProtectedRoutes, { InverseProtectedRoutes } from "model/ProtectedRoutes";

import InDevelopmentPage from "layouts/inDevelopment";

// KEY
import { v4 as uuidv4 } from "uuid";
import { refreshToken } from "./util/APIHelper";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, layout, sidenavColor, transparentSidenav, whiteSidenav, darkMode, token } =
    controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the miniSidenav state
  const handleSidenavOpen = () => setMiniSidenav(dispatch, !miniSidenav);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Auth useEffect
  useEffect(() => {
    onAuthStateChanged(auth, async (us) => {
      if (us) {
        if (sessionStorage.getItem("timer.lastRefreshed") && token.length>0) {
          const time = sessionStorage.getItem("timer.lastRefreshed")
          if ((Date.now() - time) > 1) {
            try {
              const refreshRespond = await refreshToken();
              if (refreshRespond.data.status === 200) {
                setUserInfo(dispatch, refreshRespond.data.message)
              }
            } catch (e) {
              await auth.signOut()
            }
            sessionStorage.setItem("timer.lastRefreshed", Date.now().toString())
          }
        } else {
          sessionStorage.setItem("timer.lastRefreshed", Date.now().toString())
        }
      } else {
        setUser(dispatch, null);
        setUserInfo(dispatch, null);
        setLogin(dispatch, false);
      }
    });
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.admin && localStorage.getItem("user.uid") !== process.env.REACT_APP_ADMIN_UID) {
        return null;
      }

      if (route.auth) {
        return (
          <Route key={uuidv4()} element={<ProtectedRoutes />}>
            <Route
              exact
              path={route.route}
              element={
                window.location.hostname !== "localhost"
                && window.location.hostname !== process.env.REACT_APP_UAT_HOSTNAME
                && route.dev ? (
                  <InDevelopmentPage />
                ) : (
                  route.component
                )
              }
              key={route.key}
            />
          </Route>
        );
      }

      if (route.login) {
        return (
          <Route key={uuidv4()} element={<InverseProtectedRoutes />}>
            <Route exact path={route.route} element={route.component} key={route.key} />
          </Route>
        );
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={
              window.location.hostname !== "localhost"
              && window.location.hostname !== process.env.REACT_APP_UAT_HOSTNAME
              && route.dev ? (
                <InDevelopmentPage />
              ) : (
                route.component
              )
            }
            key={route.key}
          />
        );
      }

      return null;
    });

  const sidenavFAB = (
    <MDBox
      display={{ xl: "none", xs: "flex" }}
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleSidenavOpen}
    >
      <Icon fontSize="small" color="inherit">
        {miniSidenav ? "menu_open" : "menu"}
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName={process.env.REACT_APP_NAME}
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {sidenavFAB}
        </>
      )}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}
