import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// MD React components
import MDBox from "../../../components/MDBase/MDBox";
import MDTypography from "../../../components/MDBase/MDTypography";
import MDButton from "../../../components/MDBase/MDButton";

// Authentication layout components
import BasicLayout from "layouts/auth/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useMaterialUIController } from "context/md";
import { auth } from "fb/firebase.utils";
import { Verify_Subtitle, Verify_Title } from "../../../model/Enums";
import { isValidEmail } from "../../../util/UtilHelper";
import { checkVerification, resendVerification } from "../../../util/APIHelper";

function Verify() {
  const [controller] = useMaterialUIController();
  const navigate = useNavigate();
  const [title, setTitle] = useState(Verify_Title.VERIFY_EMAIL)
  const [subtitle, setSubtitle] = useState(Verify_Subtitle.VERIFY_EMAIL)
  const [resend, setResend] = useState(false)
  const [email, setEmail] = useState(useParams().email)
  const [resendTimer, setResendTimer] = useState(true)

  useEffect(() => {
    let mounted = true
    if (email) {
      // since there are email check if it is a valid email and call function to check verify

      if (isValidEmail(email)) {
        checkVerification(email).then(res => {
          if (mounted) {
            if (res.data.message === "UNVERIFIED") {
              setResend(true)
              setTitle(Verify_Title.VERIFY_FALSE)
              setSubtitle(Verify_Subtitle.VERIFY_FALSE)
            } else if (res.data.message === "VERIFIED") {
              setResend(false)
              setTitle(Verify_Title.VERIFY_TRUE)
              setSubtitle(Verify_Subtitle.VERIFY_TRUE)
            }
          }
        }).catch(e => {
          if (e.response.data.message.includes("User don't exist")) {
            setTitle(Verify_Title.VERIFY_NULL)
            setSubtitle(Verify_Subtitle.VERIFY_NULL)
          }
        })
      } else {
        setTitle(Verify_Title.VERIFY_NULL)
        setSubtitle(Verify_Subtitle.VERIFY_NULL)
      }
    }
    return () => mounted = false;
  }, []);

  const handleResend = async () => {
    setResendTimer(false);
    const res = await resendVerification(email)
    if (res.data.message === "SENT") {
      sendPreset()
    }
    setTimeout(() => {
      setResendTimer(true)
    }, 10000)
  }

  const sendPreset = () => {
    setResend(false)
    setTitle(Verify_Title.VERIFY_EMAIL)
    setSubtitle(Verify_Subtitle.VERIFY_EMAIL)
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={resend?1:0}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {title}
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            {subtitle}
          </MDTypography>
        </MDBox>
        <MDBox pt={0} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mt={4} mb={1}>
              {
                (resend)?(
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={handleResend}
                    disabled={!resendTimer}
                  >
                    Resend Email
                  </MDButton>
                ):null
              }
            </MDBox>
            <MDBox mt={0} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                {"back to "}
                <MDTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Login
                </MDTypography>
                {" or "}
                <MDTypography
                  component={Link}
                  to="/"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Home
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Verify;
