export const Verify_Title = {
  VERIFY_EMAIL: "Verification Email Sent",
  VERIFY_TRUE: "Email Already Verified",
  VERIFY_FALSE: "Email Not Verified",
  VERIFY_NULL: "User Not Found"
}

export const Verify_Subtitle = {
  VERIFY_EMAIL: "Check your inbox for verification steps.",
  VERIFY_TRUE: "You're good to go!",
  VERIFY_FALSE: "Please verify your email address.",
  VERIFY_NULL: "Check the user information and try again."
}