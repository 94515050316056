// Images
import bgProject from "assets/images/bg-project.jpg";
import bgApplication from "assets/images/bg-code.jpg";
import bgTool from "assets/images/bg-tools.jpg";

// @mui material components
import Grid from "@mui/material/Grid";

// MD React components
import MDBox from "components/MDBase/MDBox";

// MD React examples
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import Footer from "components/MDComponents/Footer";

// Base components
import InDevelopmentCard from "components/Components/Cards/InDevelopmentCard";
import ProfileBanner from "components/Components/Cards/ProfileBanner";
import LinkBanner from "components/Components/Cards/LinkBanner";

function Home() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mb={3}>
          <ProfileBanner />
        </MDBox>
        <MDBox mb={3}>
          {/* Sections shortcut mdbox here */}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={3}>
              <InDevelopmentCard title={{ text: "Bookmarks" }} icon={{ color: "info" }} />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <LinkBanner title="Projects" link="/projects" bgImage={bgProject} />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <LinkBanner title="Applications" link="/applications" bgImage={bgApplication} />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <LinkBanner title="Tools" link="/tools" bgImage={bgTool} />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <InDevelopmentCard
                title={{ text: "Github Calendar Chart" }}
                icon={{ color: "info" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InDevelopmentCard title={{ text: "Github Graph Chart" }} icon={{ color: "info" }} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Home;
