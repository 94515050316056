// Authentication layout components
import BasicLayout from "layouts/redirect/components/layout";

import LottieAnimation from "../../components/Components/Items/LottieAnimation";

// Images
import redirectLottie from "assets/lottie/lottie_redirect.json";

function RedirectPage() {

  return (
    <BasicLayout>
      <LottieAnimation  animationData={redirectLottie} />
    </BasicLayout>
  );
}

export default RedirectPage;
