import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { login } from "../util/APIHelper";

const config = {
  apiKey: "AIzaSyCr5_uUleTe_VBX2_-lqbL66RXueSZN6Rw",
  authDomain: "main-354f7.firebaseapp.com",
  projectId: "main-354f7",
  storageBucket: "main-354f7.appspot.com",
  messagingSenderId: "734927321154",
  appId: "1:734927321154:web:ce2b98cee87178488495db",
  measurementId: "G-H1NP0MKE6J"
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();

provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = async () => {
  try {
    // Authenticate user with Google
    const result = await auth.signInWithPopup(provider);
    if (result.user) {
      const { uid, email } = result.user;
      const response = await login(uid, email, undefined);
      return response.data;
    }
  } catch (error) {
    console.error("Error during Google authentication:", error);
    return {};
  }
}

export const signInWithGoogle2 = () => auth.signInWithRedirect(provider);

export const signInWithEmail = (email, password) => auth.signInWithEmailAndPassword(email, password)

export default firebase;
