// @mui material components
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export default styled(Box)(({ theme }) => {
  const { palette, borders } = theme;

  const { inputBorderColor, transparent } = palette;
  const { borderRadius, borderWidth } = borders;

  return {
    display: "flex",
    alignItems: "center",
    border: `${borderWidth[1]} solid ${inputBorderColor} !important`,
    borderRadius: borderRadius.md,
    padding: "16px", // Equivalent to p-16

    marginTop: "10px", // Equivalent to mt-10

    "&.dropzone": {
      background: `${transparent.main} !important`,
    },

    /* Rest of your styles */
  };
});
