import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// MD React components
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import MDInput from "components/MDBase/MDInput";
import MDButton from "components/MDBase/MDButton";

// Authentication layout components
import BasicLayout from "layouts/auth/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

// Firebase
import { signInWithGoogle, signInWithGoogle2, signInWithEmail } from "fb/firebase.utils";

import { setLogin, setUser, setUserInfo, useMaterialUIController } from "../../../context/md";

import { auth } from "fb/firebase.utils";
import MDSnackbar from "../../../components/MDBase/MDSnackbar";
import { isValidEmail } from "../../../util/UtilHelper";
import { login } from "../../../util/APIHelper";

function SignIn() {
  const [controller, dispatch] = useMaterialUIController();
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isIOSAddToHomeScreen, setIsIOSAddToHomeScreen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("")

  useEffect(() => {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };

    const isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;

    if (isIos() && isInStandaloneMode()) {
      setIsIOSAddToHomeScreen(true);
    }
  }, []);

  const signInGoogle = async () => {
    setLoading(true);
    const res = await signInWithGoogle();
    setLoading(false);

    if (res.status === 200 && res.message && auth.currentUser) {
      if (res.message.action === "NEW_ACCOUNT_GOOGLE") {
        sessionStorage.setItem(res.message.action, res.message.token);
        sessionStorage.setItem("DEFAULT_NAME", auth.currentUser.displayName);
        window.location.href = "/sign-up"
      } else {
        setUser(dispatch, auth.currentUser);
        setUserInfo(dispatch, res.message)
        setLogin(dispatch, true);
      }
    } else {
      setErrorSB(true);
    }
  }

  const signInEmail = async () => {
    let emailErrorVar

    emailErrorVar = isValidEmail(email) ? "" : "Please enter a valid email address"
    emailErrorVar = email.length === 0 ? "Email address is required" : emailErrorVar
    emailErrorVar = emailError === "User do not exist"?emailError:emailErrorVar
    setEmailError(emailErrorVar)

    const passwordErrorVar=(password.length === 0) ? "Password is required" : ""
    setPasswordError(passwordErrorVar)

    if (emailErrorVar.length===0 && passwordErrorVar.length===0) {
      setLoading(true);
      try {
        const loginResponse = await login(undefined, email, password);
        if (loginResponse.data.message.action === "NEW_ACCOUNT_EMAIL") {
          setEmailError("User do not exist")
        }
        if (loginResponse.data.message.action === "VERIFY_EMAIL") {
          window.location.href = `/verify/${loginResponse.data.message.email}`
        }

        if (loginResponse.data.message.token) {
          await signInWithEmail(email, password);
          setUser(dispatch, auth.currentUser);
          setUserInfo(dispatch, loginResponse.data.message)
          setLogin(dispatch, true);
        }
      } catch (e) {
        if (e.response.data.message === "User don't exist in Firebase") {
          setEmailError("User do not exist")
        }
        setErrorSB(true)
      }
      setLoading(false);
    }
  }

  const closeErrorSB = () => setErrorSB(false);
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content="An error occurred during login. Please try again."
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const handleEmailChange = (e) => {
    const val = e.target.value
    setEmail(val)
    if ((val.length>0 && isValidEmail(val)) || val.length===0) {
      setEmailError("")
    }

    if (emailError.length>0) {
      if (!isValidEmail(val) && val.length>0) {
        setEmailError("Please enter a valid email address")
      }
    }
  }

  const handlePasswordChange = (e) => {
    const val = e.target.value
    setPassword(val)
    setPasswordError("")
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography
            variant="h4"
            fontWeight="medium"
            color="white"
            mt={1}
            mb={isIOSAddToHomeScreen ? 1 : 0}
          >
            Sign in
          </MDTypography>

          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={null} href="#" variant="body1" color="white">
                <FacebookIcon color="disabled" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={null}
                href="#"
                variant="body1"
                color="white"
                onClick={null}
              >
                <GitHubIcon color="disabled" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={(!isIOSAddToHomeScreen)?MuiLink:null}
                href="#"
                variant="body1"
                color="white"
                onClick={(!isIOSAddToHomeScreen)?signInGoogle:null}
              >
                <GoogleIcon color={(!isIOSAddToHomeScreen)?"inherit":"disabled"} />
              </MDTypography>
            </Grid>
          </Grid>

        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => handleEmailChange(e)}
                helperText={emailError}
                error={emailError.length>0}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={(e) => handlePasswordChange(e)}
                helperText={passwordError}
                error={passwordError.length>0}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={async () => {
                  await signInEmail();
                }}
              >
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      {renderErrorSB}
    </BasicLayout>
  );
}

export default SignIn;
