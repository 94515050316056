import PropTypes from "prop-types";
import MDBox from "../../MDBase/MDBox";

function Banner({ image, ...rest }) {
  return (
    <MDBox
      {...rest}
      width="calc(100%)"
      borderRadius="xl"
      pt={6}
      pb={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          image &&
          `${linearGradient(
            rgba(gradients.dark.main, 0.4),
            rgba(gradients.dark.state, 0.4)
          )}, url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    />
  );
}

// Setting default props for the CoverLayout
Banner.defaultProps = {
  image: "35vh",
};

// Typechecking props for the CoverLayout
Banner.propTypes = {
  image: PropTypes.string,
};

export default Banner;
