import { Navigate, Outlet } from "react-router-dom";
// MD React contexts
import { useMaterialUIController } from "context/md";

const useAuth = () => {
  const [controller] = useMaterialUIController();
  const { loggedIn } = controller;
  return loggedIn;
};
export function InverseProtectedRoutes() {
  return useAuth() ? <Navigate to="/" /> : <Outlet />;
}
export default function ProtectedRoutes() {
  return useAuth() ? <Outlet /> : <Navigate to="/" />;
}
