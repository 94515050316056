// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// MD React components
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";

// MD React context
import { useMaterialUIController } from "context/md";

// Styling
import "./index.css";

/**
 * usage:
 * <InDevelopmentCard
 *    title={{ text: "Github Calendar Chart" }}
 *    icon={{ color: "info"}}
 * />
 */
function InDevelopmentCard({ bgColor, title, percentage, icon }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card sx={{ overflow: "hidden" }}>
      <MDBox
        bgColor={bgColor}
        variant="gradient"
        sx={({ palette: { background } }) => ({
          background: darkMode && background.card,
        })}
      >
        <MDBox p={2}>
          <Grid container alignItems="center">
            <Grid item xs={8}>
              <MDBox lineHeight={1}>
                <MDTypography
                  variant="button"
                  color={bgColor === "white" ? "text" : "white"}
                  opacity={bgColor === "white" ? 1 : 0.7}
                  textTransform="capitalize"
                  fontWeight={title.fontWeight}
                >
                  In Development
                </MDTypography>
                <MDTypography
                  variant="h5"
                  fontWeight="bold"
                  color={bgColor === "white" ? "dark" : "white"}
                >
                  {title.text}
                  <MDTypography variant="button" color={percentage.color} fontWeight="bold">
                    {percentage.text}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={4}>
              <MDBox
                variant="gradient"
                bgColor={bgColor === "white" ? icon.color : "white"}
                color={bgColor === "white" ? "white" : "dark"}
                width="4rem"
                height="4rem"
                marginLeft="auto"
                borderRadius="md"
                display="flex"
                justifyContent="center"
                alignItems="center"
                shadow="md"
              >
                <Icon fontSize="medium" color="inherit" className="rotate">
                  settings
                </Icon>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of MiniStatisticsCard
InDevelopmentCard.defaultProps = {
  bgColor: "white",
  title: {
    fontWeight: "light",
    text: "",
  },
  percentage: {
    color: "success",
    text: "",
  },
};

// Typechecking props for the MiniStatisticsCard
InDevelopmentCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  }).isRequired,
};

export default InDevelopmentCard;
