// Images import
import bgImage from "assets/images/bg-galaxy-blur.jpg";
import logoReact from "assets/images/small-logos/logo-react.svg";
import logoPython from "assets/images/small-logos/logo-python.svg";
import logoJava from "assets/images/small-logos/logo-java.svg";
import logoFirebase from "assets/images/small-logos/logo-firebase.svg";
import logoMysql from "assets/images/small-logos/logo-mysql.svg";
import logoFlutter from "assets/images/small-logos/logo-flutter.svg";
import logoKotlin from "assets/images/small-logos/logo-kotlin.svg";
import logoNodejs from "assets/images/small-logos/logo-nodejs.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";

// @mui material components
import Grid from "@mui/material/Grid";

// react-router components
import { Link } from "react-router-dom";

// MD Base components
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import MDButton from "components/MDBase/MDButton";

// Base components
import Banner from "components/Base/Banner";
import Icons from "../../Items/Icons";

function ProfileBanner() {
  return (
    <Banner image={bgImage}>
      <Grid container spacing={3}>
        {/* First Part */}
        <Grid item xs={0} md={0}> {/* Changed xs to 0 from 12, md to 0 from 6, commented out the contents */}
          <MDBox>
            <MDTypography
              variant="h2"
              color="white"
              p={2}
              sx={{ textShadow: "2px 3px 5px rgba(0,0,0,0.5)" }}
            >
              {/*Explore My Work as a Software Engineer*/}
            </MDTypography>
            <MDTypography
              variant="subtitle1"
              color="white"
              pl={2}
              sx={{ textShadow: "2px 3px 5px rgba(0,0,0,0.5)" }}
            >
              {/*Crafting Innovative and Impactful Solutions*/}
            </MDTypography>
          </MDBox>
        </Grid>

        {/* Second Part */}
        <Grid item xs={12} md={12}> {/* Changed md to 12 from 6 */}
          <MDBox display="flex" justifyContent="center" p={2}>
            <Link to="/portfolio">
              <MDButton variant="gradient" color="info" size="large">
                Discover More
              </MDButton>
            </Link>
          </MDBox>

          <MDBox display="flex" justifyContent="center" p={2}>
            <Grid container justifyContent="center">
              <Icons title="React JS" image={logoReact} background="#222222" grid />
              <Icons title="Node JS" image={logoNodejs} background="#FFFFFF" grid />
              <Icons title="Flutter" image={logoFlutter} background="#F2F2F2" grid />
              <Icons title="Kotlin" image={logoKotlin} background="#F2F2F2" grid />
              <Icons title="MySQL" image={logoMysql} background="#F2F2F2" grid />
              <Icons title="Firebase" image={logoFirebase} background="#196DDD" grid />
              <Icons title="Python" image={logoPython} background="#F2F2F2" grid />
              <Icons title="Java" image={logoJava} background="#DFDFDF" grid />
              <Icons title="Atlassian" image={logoAtlassian} background="#DFDFDF" grid />
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </Banner>
  );
}
export default ProfileBanner;
