// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// MD React components
import MDBox from "../../../components/MDBase/MDBox";
import MDTypography from "../../../components/MDBase/MDTypography";
import MDInput from "../../../components/MDBase/MDInput";
import MDButton from "../../../components/MDBase/MDButton";

// Authentication layout components
import CoverLayout from "../../dummy/authentication/components/CoverLayout";

// Images
import bgImage from "../../../assets/images/bg-sign-up-cover.jpeg";
import { useEffect, useState } from "react";
import { isValidEmail, checkSecurePassword } from "util/UtilHelper";
import Tooltip from "@mui/material/Tooltip";

import { auth } from "fb/firebase.utils";
import { setLogin, setUser, setUserInfo, useMaterialUIController } from "../../../context/md";
import { signup } from "../../../util/APIHelper";

function SignUp() {
  const [controller, dispatch] = useMaterialUIController();
  const [googleToken, setGoogleToken] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    const googleNewAcc = "NEW_ACCOUNT_GOOGLE"
    const defaultName = "DEFAULT_NAME"
    if(sessionStorage.getItem(googleNewAcc) && sessionStorage.getItem(defaultName)){
      setGoogleToken(sessionStorage.getItem(googleNewAcc))
      sessionStorage.removeItem(googleNewAcc)
      setName(sessionStorage.getItem(defaultName))
      sessionStorage.removeItem((defaultName))
    }
  },[]);

  const handleNameChange = (e) => {
    const val = e.target.value
    setName(val)

    if(val.length>0){
      setNameError("")
    }
  }

  const handleEmailChange = (e) => {
    const val = e.target.value
    setEmail(val)
    if ((val.length>0 && isValidEmail(val)) || val.length===0) {
      setEmailError("")
    }

    if (emailError.length>0) {
      if (!isValidEmail(val) && val.length>0) {
        setEmailError("Please enter a valid email address")
      }
    }
  }

  const handlePasswordChange = (e) => {
    const val = e.target.value
    setPassword(val)
    if (checkSecurePassword(val).length===0 || val.length === 0) {
      setPasswordError("")
    } else {
      if (passwordError.includes("required") && checkSecurePassword(val).length>0) {
        setPasswordError("Weak password")
      }
    }
  }

  const handleSignUp = async () => {
    if (name.length === 0) {
      setNameError("Name is required")
    }

    let emailErrorVar

    emailErrorVar = isValidEmail(email) ? "" : "Please enter a valid email address"
    emailErrorVar = email.length === 0 ? "Email address is required" : emailErrorVar
    emailErrorVar = emailError === "User already exist"?emailError:emailErrorVar
    setEmailError(emailErrorVar)

    let passwordErrorVar
    const passwordErrors = checkSecurePassword(password)

    passwordErrorVar = (passwordErrors.length > 0) ? "Weak password" : ""
    passwordErrorVar = (password.length === 0) ? "Password is required" : passwordErrorVar
    setPasswordError(passwordErrorVar)

    if (emailErrorVar.length===0 && passwordErrorVar.length === 0 && nameError.length === 0) {
      try {
        const signUpEmailResponse = await signup(name, email, password, null)
        if (signUpEmailResponse.data.status === 200) {
          if (signUpEmailResponse.data.message.action === "VERIFY_EMAIL") {
            window.location.href = "/verify"
          }
        }
      } catch (e) {
        const error = e.response.data.message
        if (error === "User already exist") {
          setEmailError("User already exist")
        }
      }
    } else if (googleToken.length > 0 && nameError.length === 0) {
      try {
        const signUpGoogleResponse = await signup(name, auth.currentUser.email, null, googleToken)
        if (signUpGoogleResponse.data.status === 200) {
          setUser(dispatch, auth.currentUser)
          setUserInfo(dispatch, signUpGoogleResponse.data.message)
          setLogin(dispatch, true)
        }
      } catch (e) {}
    }
  }

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign Up
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            {
              (googleToken.length===0)?
                ("Enter your email and password to register"):("Enter your name to register")
            }
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                value={name}
                onChange={(e)=>{handleNameChange(e)}}
                type="text"
                label="Name"
                variant="standard"
                fullWidth error={nameError.length>0}
                helperText={nameError}
              />
            </MDBox>
            {
              (googleToken.length===0)?
                (<>
                  <MDBox mb={2}>
                    <MDInput
                      value={email}
                      onChange={(e)=>{handleEmailChange(e)}}
                      type="email"
                      label="Email"
                      variant="standard"
                      fullWidth error={emailError.length>0}
                      helperText={emailError}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <Tooltip title={(passwordError.length>0)?checkSecurePassword(password):""} placement={"bottom-start"}>
                      <MDInput
                        value={password}
                        onChange={(e)=>{handlePasswordChange(e)}}
                        type="password"
                        label="Password"
                        variant="standard"
                        fullWidth error={passwordError.length>0}
                        helperText={passwordError}
                      />
                    </Tooltip>
                  </MDBox>
                </>):null
            }
            <MDBox mt={4} mb={1}>
              <MDButton onClick={handleSignUp} variant="gradient" color="info" fullWidth>
                {
                  (googleToken.length===0)?"sign up":"continue"
                }
              </MDButton>
            </MDBox>
            {
              (googleToken.length===0)?
                (
                  <MDBox mt={3} mb={1} textAlign="center">
                    <MDTypography variant="button" color="text">
                      Already have an account?{" "}
                      <MDTypography
                        component={Link}
                        to="/login"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign In
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                ):null
            }
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default SignUp;
