export function isValidEmail (email) {
  // Regular expression for basic email validation
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  return emailRegex.test(email);
}

export function checkSecurePassword(password) {
  const weaknesses = [];
  let errorString = "Password should contain at least "
  // Check minimum length
  if (password.length < 8) {
    weaknesses.push("characters");
    errorString = errorString + "8 "
  } else {
    errorString = errorString + "1 "
  }

  // Check for uppercase letters
  if (!/[A-Z]/.test(password)) {
    weaknesses.push("uppercase letter");
  }

  // Check for lowercase letters
  if (!/[a-z]/.test(password)) {
    weaknesses.push("lowercase letter");
  }

  // Check for digits
  if (!/\d/.test(password)) {
    weaknesses.push("digit");
  }

  // Check for special characters
  if (!/[!@#$%^&*()\-_=+[\]{}|\\;:'",.<>/?]/.test(password)) {
    weaknesses.push("special character");
  }

  errorString = errorString + weaknesses.join(", 1 ") + "."

  // Return weaknesses or an empty string if password is secure
  return weaknesses.length > 0 ? errorString : "";
}