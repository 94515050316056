import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';

const LottieAnimation = ({ animationData }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: containerRef.current,
      animationData: animationData,
      loop: true, // set to true for looped animation
      autoplay: true, // set to true to start the animation when loaded
    });

    return () => {
      // Cleanup animation when the component unmounts
      animation.destroy();
    };
  }, [animationData]);

  return <div ref={containerRef} />;
};

LottieAnimation.propTypes = {
  animationData: PropTypes.object.isRequired, // Replace 'object' with the appropriate data type of your animationData
};

export default LottieAnimation;
