// @mui material components
import Grid from "@mui/material/Grid";

// MD React components
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";

// MD React examples
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import Footer from "components/MDComponents/Footer";

function Portfolio() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container>
          <MDTypography>dsf</MDTypography>
          <div>
            <h1>My Portfolio</h1>
            <p>Hi, Im John, a software developer based in San Francisco.</p>
            <h2>Projects</h2>
            <ul>
              <li>
                <h3>Project 1: E-commerce Website</h3>
                <p>
                  I designed and developed a fully functional e-commerce website from scratch using
                  MERN stack. The website includes features like user authentication, product
                  catalog, shopping cart, and checkout. Its deployed on AWS and can handle high
                  traffic and transactions.
                </p>
                <a href="https://example.com/project1">View Project</a>
              </li>
              <li>
                <h3>Project 2: Mobile App</h3>
                <p>
                  I worked as a team lead to develop a cross-platform mobile app using React Native
                  and Firebase. The app allows users to book appointments with healthcare providers
                  and manage their medical records. Its highly rated on app stores and has over 100k
                  100k downloads.
                </p>
                <a href="https://example.com/project2">View Project</a>
              </li>
            </ul>
            <h2>Skills</h2>
            <ul>
              <li>JavaScript</li>
              <li>React.js</li>
              <li>Node.js</li>
              <li>Express.js</li>
              <li>MongoDB</li>
              <li>Firebase</li>
              <li>Git</li>
            </ul>
            <h2>Experience</h2>
            <ul>
              <li>
                <h3>Software Developer, Acme Inc.</h3>
                <p>San Francisco, CA | 2019 - present</p>
                <ul>
                  <li>
                    Developed and maintained multiple web applications using React.js and Node.js
                  </li>
                  <li>
                    Collaborated with product and design teams to deliver high-quality user
                    experiences
                  </li>
                  <li>
                    Implemented automated testing and continuous integration to improve development
                    efficiency
                  </li>
                </ul>
              </li>
              <li>
                <h3>Web Developer, XYZ Agency</h3>
                <p>New York, NY | 2017 - 2019</p>
                <ul>
                  <li>
                    Built and optimized websites for various clients using WordPress, HTML/CSS, and
                    JavaScript
                  </li>
                  <li>Managed website hosting and deployment using AWS and cPanel</li>
                  <li>
                    Performed website maintenance and troubleshooting to ensure optimal performance
                    and security
                  </li>
                </ul>
              </li>
            </ul>
            <h2>Education</h2>
            <ul>
              <li>
                <h3>Bachelor of Science in Computer Science</h3>
                <p>University of California, Berkeley | 2013 - 2017</p>
                <p>GPA: 3.9/4.0</p>
              </li>
            </ul>
          </div>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Portfolio;
