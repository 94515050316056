/** 
  All of the routes for the MD React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.


  Custom Keys and Practices:
  1. [dev: true] - replaces component with inDevelopmentPage when url is not localhost (deployed)
  2. [hidden: true] - hides the page from showing on sideNav, can also be used on collapse
  3. REDIRECTS - dynamic redirect, make a new entry in database
*/

//#region MD React layouts
// import Analytics from "layouts/dummy/dashboards/analytics";
import Sales from "layouts/dummy/dashboards/sales";
import ProfileOverview from "layouts/dummy/pages/profile/profile-overview";
// import AllProjects from "layouts/dummy/pages/profile/all-projects";
// import NewUser from "layouts/dummy/pages/users/new-user";
import Settings from "layouts/dummy/pages/account/settings";
// import Billing from "layouts/dummy/pages/account/billing";
// import Invoice from "layouts/dummy/pages/account/invoice";
import Timeline from "layouts/dummy/pages/projects/timeline";
// import PricingPage from "layouts/dummy/pages/pricing-page";
// import Widgets from "layouts/dummy/pages/widgets";
// import Charts from "layouts/dummy/pages/charts";
// import Notifications from "layouts/dummy/pages/notifications";
import Kanban from "layouts/dummy/applications/kanban";
import Wizard from "layouts/dummy/applications/wizard";
import DataTables from "layouts/dummy/applications/data-tables";
import Calendar from "layouts/dummy/applications/calendar";
// import NewProduct from "layouts/dummy/ecommerce/products/new-product";
// import EditProduct from "layouts/dummy/ecommerce/products/edit-product";
// import ProductPage from "layouts/dummy/ecommerce/products/product-page";
// import OrderList from "layouts/dummy/ecommerce/orders/order-list";
// import OrderDetails from "layouts/dummy/ecommerce/orders/order-details";
// import SignInCover from "layouts/dummy/authentication/sign-in/cover";
// import SignInIllustration from "layouts/dummy/authentication/sign-in/illustration";
// import SignUpCover from "layouts/dummy/authentication/sign-up/cover";
// import ResetCover from "layouts/dummy/authentication/reset-password/cover";
//endregion


// Main Layouts
import SignIn from "layouts/auth/sign-in";
import SignUp from "layouts/auth/sign-up";
import Logout from "layouts/auth/logout";
import Verify from "layouts/auth/verify";
import Home from "layouts/home";
import Admin from "./layouts/admin";
import Portfolio from "layouts/portfolio";
import ProjectAll from "layouts/projects/all";
import ToolAll from "layouts/tools/all";
import ApplicationAll from "layouts/applications/all";


import TestUpload from "layouts/test/testUpload";

// MD React components
import MDAvatar from "components/MDBase/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";
import { Navigate } from "react-router-dom";
import RedirectRoutes from "./model/RedirectRoutes";

// KEY
import { v4 as uuidv4 } from "uuid";

const routes = [
  {
    key: uuidv4(),
    route: "/",
    component: <Home />,
  },
  {
    // TODO - TEST UPLOAD
    dev: true,
    auth: true,
    key: "test-upload",
    type: "collapse",
    noCollapse: true,
    name: "Test Upload",
    route: "/test-upload",
    icon: <Icon fontSize="medium">admin_panel_settings_icon</Icon>,
    component: <TestUpload />,
  },
  {
    profile: true,
    auth: true,
    key: "profile",
    type: "collapse",
    name: "Profile",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        // TODO - PRODUCTION
        dev: true,
        key: "profile",
        name: "My Profile",
        route: "/profile",
        component: <ProfileOverview />,
      },
      {
        // TODO - PRODUCTION
        dev: true,
        auth: true,
        key: "settings",
        name: "Settings",
        route: "/profile/settings",
        component: <Settings />,
      },
      {
        key: uuidv4(),
        name: "Logout",
        route: "/logout",
        component: <Logout />,
      },
    ],
  },

  { auth: true, type: "divider", key: uuidv4(), },
  {
    // TODO - PRODUCTION
    dev: true,
    type: "collapse",
    key: "portfolio",
    noCollapse: true,
    name: "Portfolio",
    route: "/portfolio",
    icon: <Icon fontSize="medium">article</Icon>,
    component: <Portfolio />,
  },
  {
    // TODO - PRODUCTION
    dev: true,
    auth: true,
    admin: true,
    key: "dashboard",
    type: "collapse",
    noCollapse: true,
    name: "Dashboard",
    route: "/dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <Sales />,
  },
  { type: "title", title: "Software", key: "title-software" },
  {
    type: "collapse",
    key: "projects",
    name: "Projects",
    icon: <Icon fontSize="medium">data_object</Icon>,
    collapse: [
      {
        // TODO - PRODUCTION
        dev: true,
        key: "projects",
        name: "All Projects",
        route: "/projects",
        component: <ProjectAll />,
      },
      {
        // TODO - PRODUCTION
        dev: true,
        key: "timeline",
        name: "Timeline",
        route: "/projects/timeline",
        component: <Timeline />,
      },
    ],
  },
  {
    key: "tools",
    type: "collapse",
    name: "Tools",
    icon: <Icon fontSize="medium">construction</Icon>,
    collapse: [
      {
        // TODO - PRODUCTION
        dev: true,
        key: "tools",
        name: "All Tools",
        route: "/tools",
        component: <ToolAll />,
      },
      {
        // TODO - PRODUCTION
        dev: true,
        hidden: true,
        key: "timeline",
        name: "Timeline",
        route: "/tools/timeline",
        component: <Timeline />,
      },
    ],
  },
  {
    key: "applications",
    type: "collapse",
    name: "Applications",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        // TODO - PRODUCTION
        dev: true,
        key: "applications",
        name: "All Applications",
        route: "/applications",
        component: <ApplicationAll />,
      },
      {
        // TODO - PRODUCTION
        dev: true,
        key: "kanban",
        name: "Kanban",
        route: "/applications/kanban",
        component: <Kanban />,
      },
      {
        // TODO - PRODUCTION
        dev: true,
        key: "wizard",
        name: "Wizard",
        route: "/applications/wizard",
        component: <Wizard />,
      },
      {
        // TODO - PRODUCTION
        dev: true,
        key: "data-tables",
        name: "Data Tables",
        route: "/applications/data-tables",
        component: <DataTables />,
      },
      {
        // TODO - PRODUCTION
        dev: true,
        key: "calendar",
        test: "calendar",
        name: "Calendar",
        route: "/applications/calendar",
        component: <Calendar />,
      },
    ],
  },
  //#region Auth
  {
    login: true,
    type: "collapse",
    noCollapse: true,
    name: "Login",
    key: uuidv4(),
    route: "/login",
    icon: <Icon fontSize="medium">login</Icon>,
    component: <SignIn />,
  },

  // TODO - [HIDDEN] SIGN UP (Modifiable contents to cater both email and google)
  // TODO - [HIDDEN] VERIFY (Reverse Login protected, just like login)
  {
    login: true,
    key: uuidv4(),
    route: "/sign-up",
    component: <SignUp/>,
  },
  {
    login: true,
    key: uuidv4(),
    route: "/verify",
    component: <Verify />,
  },
  {
    login: true,
    key: uuidv4(),
    route: "/verify/:email",
    component: <Verify />,
  },

  //#endregion
  {
    // TODO - PRODUCTION
    dev: true,
    auth: true,
    admin: true,
    key: "admin",
    type: "collapse",
    noCollapse: true,
    name: "Admin",
    route: "/admin",
    icon: <Icon fontSize="medium">admin_panel_settings_icon</Icon>,
    component: <Admin />,
  },

  /** redirects */
  {
    key: uuidv4(),
    route: "/:param",
    component: <RedirectRoutes />,
  },
  /** redirects */
];

export default routes;
