/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useMemo, useReducer } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// The MD React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// MD React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "LOGIN": {
      return { ...state, loggedIn: action.value };
    }
    case "USER": {
      return { ...state, user: action.value };
    }

    case "UID": {
      return { ...state, uid: action.value };
    }
    case "TOKEN": {
      return { ...state, token: action.value };
    }
    case "NAME": {
      return { ...state, fullName: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// MD React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    layout: "dashboard",
    darkMode: localStorage.getItem("darkMode") !== "false",

    // Auth
    loggedIn: localStorage.getItem("isLoggedIn") === "true",
    user: null,
    token: localStorage.getItem("user.token") ?? "",

    // User Infos
    uid: localStorage.getItem("user.uid") ?? "",
    fullName: localStorage.getItem("user.name") ?? "",
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// MD React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => {
  let color;
  if (!value) {
    color = "#f0f2f5";
  } else {
    color = "#1a2035";
  }
  localStorage.setItem("darkMode", value);
  document.querySelector('meta[name="theme-color"]')?.setAttribute("content", color);
  dispatch({ type: "DARKMODE", value });
};
const setLogin = (dispatch, value) => {
  localStorage.setItem("isLoggedIn", value)
  dispatch({ type: "LOGIN", value });
}
const setUser = (dispatch, value) => {
  if (value !== null) {
    localStorage.setItem("user.uid", value.uid);
    dispatch({ type: "UID", value: value.uid });
  } else {
    localStorage.removeItem("user.uid")
  }
  dispatch({ type: "USER", value });
}
const setUserInfo = (dispatch, user) => {
  // TODO USER BOUND CONFIGS
  if (user !== null) {
    localStorage.setItem("user.name", user.name)
    localStorage.setItem("user.token", user.token)
    dispatch({ type: "NAME", value: (user.name) });
    dispatch({ type: "TOKEN", value: (user.token) });
  } else {
    localStorage.removeItem("user.name")
    localStorage.removeItem("user.token")
    dispatch({ type: "NAME", value: "" });
    dispatch({ type: "TOKEN", value: "" });
  }
}

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setLayout,
  setDarkMode,
  setLogin,
  setUser,
  setUserInfo
};
