// @mui material components
import Grid from "@mui/material/Grid";

// MD React components
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";

// MD React examples
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import Footer from "components/MDComponents/Footer";
import Icon from "@mui/material/Icon";
import MDButton from "../../components/MDBase/MDButton";
import { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

function Admin() {
  const [dialogOpen, setDialogOpen] = useState(false);

  const renderDialog = () => {
    return <div>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <MDTypography>To subscribe to this website, please enter your email address here. We
            will send updates occasionally.</MDTypography>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions>
      </Dialog>
    </div>
  }

  const handleClose = () => {
    setDialogOpen(false)
  }
  const handleClickOpen = () => [
    setDialogOpen(true)
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container>
          <MDTypography>dsf</MDTypography>
          <MDButton
            variant="outlined"
            color="secondary"
            size="small"
            circular
            iconOnly
            onClick={handleClickOpen}
          >
            <Icon>priority_high</Icon>
          </MDButton>
        </Grid>
      </MDBox>
      <Footer />
      {renderDialog()}
    </DashboardLayout>
  );
}

export default Admin;
