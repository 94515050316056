import { useEffect } from "react";
import { useParams } from "react-router-dom";
// API
import { redirectRoute } from "util/APIHelper";

import RedirectPage from "../layouts/redirect";

export default function RedirectRoutes() {
  const param = useParams().param;

  useEffect(() => {
    let savedRoute = sessionStorage.getItem(`route.${param}`)
    const runAsync = async () => {
      try {
        const redirectResponse = await redirectRoute(param);
        if (redirectResponse.status === 200) {
          let loopPrevention = "/"
          if (param !== redirectResponse.data.message) {
            loopPrevention = redirectResponse.data.message;
          }
          sessionStorage.setItem(`route.${param}`, loopPrevention)
          window.location.href = loopPrevention;
        } else {
          sessionStorage.setItem(`route.${param}`, "/")
          window.location.href = "/";
        }
      } catch (e) {
        sessionStorage.setItem(`route.${param}`, "/")
        window.location.href = "/";
      }
    };

    if (savedRoute === null) {
      runAsync();
    } else {
      if (savedRoute === param) {
        savedRoute = "/"
        sessionStorage.setItem(`route.${param}`, savedRoute)
      }
      setTimeout(() => {
        window.location.href = savedRoute;
      }, 1200);
    }
  }, [param]);

  return <RedirectPage />
}
