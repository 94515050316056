import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const redirectRoute = async (route) => instance.get(`/route/${route}`);

//#region Test
const headers = {
  Authorization: `Bearer ${localStorage.getItem("user.token")}`
}

export const testPostHeader = async (data) =>
  instance.post("auth/test", { uid: data }, {header});
//#endregion

//#region Auth
export const login = async (uid, email, password) => {
  const body = {
    email: email
  }
  if (uid) {
    body.uid = uid
  }
  if (password) {
    body.password = password
  }

  return await instance.post("auth/login", body)
}

export const signup = async (name, email, password, token) => {
  const body = {
    email: email,
    password: password,
    name: name,
    token: token
  }

  return await instance.post("auth/sign-up", body)
}

export const checkVerification = async (email) => instance.get(`auth/verify-email/${email}`);

export const resendVerification = async (email) => instance.get(`auth/send-verification/${email}`);

export const refreshToken = async () => instance.get('auth/refresh-token',{headers});
//#endregion

//region Apps
export const getApps = async () => instance.get("/apps");
//endregion

//region Projects
export const getProjects = async () => instance.get("/projects");
//endregion

//region Tools
export const getTools = async () => instance.get("/tools");
//endregion

//region Dummy
export const getRolodex = async (amount) => instance.get(`/rolodex/${amount}`);

//region Dashboard
export const getAnalyticsSalesCountry = async () =>
  instance.get("/dummy/dashboard/analytics/salesbycountry");

export const getAnalyticsWebsiteViews = async () =>
  instance.get("/dummy/dashboard/analytics/websiteviews");

export const getAnalyticsDailySales = async () => instance.get("/dummy/dashboard/analytics/dailysales");

export const getAnalyticsCompletedTasks = async () =>
  instance.get("/dummy/dashboard/analytics/completedtasks");

export const getSalesChannels = async () => instance.get("/dummy/dashboard/sales/channels");

export const getSalesRevenue = async () => instance.get("/dummy/dashboard/sales/revenue");

export const getSalesSalesAge = async () => instance.get("/dummy/dashboard/sales/salesbyage");

export const getSalesSalesCountry = async () => instance.get("/dummy/dashboard/sales/salesbycountry");

export const getSalesTopSelling = async () => instance.get("/dummy/dashboard/sales/topsellingproducts");
//endregion

//region Pages
export const getProfileConversations = async () =>
  instance.get("/dummy/pages/profile/profileoverview/conversations");

export const getAccountBasicInfo = async () => instance.get("/dummy/pages/account/settings/basicinfo");

export const getProjectsTimeline = async () => instance.get("/dummy/pages/projects/timeline");

export const getWidgetTasks = async () => instance.get("/dummy/pages/widgets/tasks");

export const getWidgetsCalories = async () => instance.get("/dummy/pages/widgets/calories");

export const getWidgetCalendar = async () => instance.get("/dummy/pages/widgets/calendar");

export const getWidgetsCategories = async () => instance.get("/dummy/pages/widgets/categories");

export const getChartsLine = async () => instance.get("/dummy/pages/charts/line");

export const getChartsLineGradient = async () => instance.get("/dummy/pages/charts/linegradient");

export const getChartsBar = async () => instance.get("/dummy/pages/charts/bar");

export const getChartsBarHorizontal = async () => instance.get("/dummy/pages/charts/barhorizontal");

export const getChartsMixed = async () => instance.get("/dummy/pages/charts/mixed");

export const getChartsBubble = async () => instance.get("/dummy/pages/charts/bubble");

export const getChartsDoughnut = async () => instance.get("/dummy/pages/charts/doughnut");

export const getChartsPie = async () => instance.get("/dummy/pages/charts/pie");

export const getChartsRadar = async () => instance.get("/dummy/pages/charts/radar");

export const getChartsPolar = async () => instance.get("/dummy/pages/charts/polar");
//endregion

//region Applications
export const getAppKanban = async () => instance.get("/dummy/applications/kanban");

export const getAppDatatable = async () => instance.get("/dummy/applications/datatable");

export const getAppCalendar = async () => instance.get("/dummy/applications/calendar");
//endregion

//region ECommerce
export const getProductsProductPage = async () => instance.get("/dummy/ecommerce/products/productpage");

export const getOrderOrderList = async () => instance.get("/dummy/ecommerce/orders/orderlist");
//endregion

//region Unused Base
export const register = async (name, email, password, phone, agency, role) =>
  instance.post("users/register", { name, email, password, phone, agency, role });

export const confirmRegister = async (id) => instance.post(`users/confirm/${id}`);

export const forgotPassword = async (email) => instance.post("users/forgotpassword", { email });

export const confirmReset = async (id, password) =>
  instance.post(`users/resetpass/${id}`, { password });

export const loginx = async (email, password) => instance.post("users/login", { email, password });

export const logout = async (token) => instance.post("users/logout", { token });

export const edit = async (userID, name, email) =>
  instance.post("/users/edit", { userID, name, email });
//endregion
//endregion
