// Image
import bgImage from "assets/images/bg-galaxy-blur.jpg";

// MUI components
import Grid from "@mui/material/Grid";

// MD React components
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";

// MD React examples
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import Footer from "components/MDComponents/Footer";
import Banner from "components/Base/Banner";

function InDevelopmentPage() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Banner image={bgImage}>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ position: "relative", py: 22, textAlign: "center" }}
          >
            <Grid item xs={11} lg={5}>
              <MDBox mb={1}>
                <MDTypography
                  variant="h2"
                  color="white"
                  fontWeight="bold"
                  sx={{ textShadow: "2px 3px 5px rgba(0,0,0,0.5)" }}
                >
                  Page In Development
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <MDTypography
                  variant="body2"
                  color="white"
                  fontWeight="light"
                  sx={{ textShadow: "2px 3px 5px rgba(0,0,0,0.5)" }}
                >
                  Constantly improving to better serve you!
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Banner>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default InDevelopmentPage;
